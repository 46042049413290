.card-container {
  width: 98vw;
  height: 550px;
  position: relative;
  bottom: 0;
  top: 7.2rem;
  left: 0;
  right: 0;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin: 0 auto;
}

.card {
  width: 100%;
  height: 100%;
}

.card .front-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card .front-content p {
  font-size: 32px;
  font-weight: 700;
  opacity: 1;
  background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100%);
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  pointer-events: none;
  transform: translateY(0%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card .content .heading {
  font-size: 32px;
  font-weight: 700;
}

/* .card:hover .content {
  transform: translateY(96%);
} */

.card:hover .front-content {
  transform: translateY(-30%);
}

.card:hover .front-content p {
  opacity: 0;
}

.button {
  --color: #a9001c;
  --color2: rgb(255, 255, 255);
  background-color: rgba(241, 240, 240, 0.715) !important;
  padding: 0.3em 1.25em;
  background-color: transparent;
  border-radius: 6px;
  border: 0.3px solid var(--color);
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-weight: 300;
  font-size: 17px;
  font-family: "Roboto", "Segoe UI", sans-serif;
  text-transform: uppercase;
  color: var(--color);
}

.button::after,
.button::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: 0.5s ease-out;
  background-color: var(--color);
}

.button::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.button:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.button:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
}

.button:hover {
  color: white !important;
  font-weight: 600;
}

.button:active {
  filter: brightness(0.7);
  transform: scale(0.98);
}
.StatusBooked {
  color: #0f9307;
  font-weight: bold;
}
.arival_date {
  color: gray;
}
.view_ticket {
  border-radius: 10px;
  color: white;
}

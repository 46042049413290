.accountform {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.group {
  position: relative;
}

.accountform .group label {
  font-size: 14px;
  color: rgb(107, 107, 107);
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.accountform .group input {
  padding: 10px;
  border-radius: 5px;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  /* margin-bottom: 20px; */

  outline: 0;
  /* width: 100%; */
  background-color: transparent;
}

.accountform .group input:placeholder-shown + label {
  top: 0px;
  background-color: transparent;
}

.accountform .group input:focus {
  border-color: #3366cc;
}

.accountform .group input:focus + label {
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: #3366cc;
  /* font-weight: 600; */
  font-size: 14px;
}

.gendericon {
  width: 30px;
  padding: 2px;
}
.centered-image {
  display: block;
  margin: 0 auto;
}
.emailcss {
  width: 100vw !important;
}
